<template>
  <div
    class="flex flex-col items-center justify-center w-full h-full space-y-4"
  >
    <icon-base width="120" height="120" icon="empty-state" />
    <div class="flex flex-col space-y-4 items-center">
      <lf-h2 class="-mb-0">
        {{ $t("OFFERS.EMPTY_HEADING") }}
      </lf-h2>
      <span
        class="text-sm text-gray-500 font-normal whitespace-pre-line text-center w-102"
      >
        {{ $t("OFFERS.EMPTY_SUBHEADING") }}
      </span>
    </div>
    <create-self-funded-offer-button
      v-if="
        !noProducts &&
        (((!isFunder || isClient) && isClientFunded) ||
          (!hasPlacementStage && isLendflowUser))
      "
      :disabled="(isClient && !isUnderwritingEnabled) || isClientUser"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth } from "@/hooks/auth";
import CreateSelfFundedOfferButton from "./CreateSelfFundedOfferButton.vue";
import { useDeals } from "@/hooks/deals";
import { useActiveWorkflowTemplateStages } from "@/hooks/workflow";

defineProps({
  isSelfFunding: {
    type: Boolean,
    default: false
  },
  noProducts: {
    type: Boolean,
    default: false
  }
});

const { hasPlacementStage } = useActiveWorkflowTemplateStages();

const { isFunder, isClient, isLendflowUser, isClientUser } = useAuth();
const { isClientFunded, isUnderwritingEnabled } = useDeals();
</script>
